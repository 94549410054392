/* Team Roster: Card */
.team-roster--card {

	// Real Name
	.team-roster__player-realname {
		text-transform: uppercase;
		font-style: italic;
		color: $body-font-color;
		line-height: 1em;
		margin-bottom: 0.1em;
	}

	// Nickname
	.team-roster__player-nickname {
		display: block;
		font-size: 40px;
		line-height: 1em;
		margin-bottom: .22em;
		letter-spacing: -0.03em;

		@media (min-width: 768px) {
			font-size: 54px;
		}

		@media (min-width: 992px) {
			font-size: 60px;
		}

		@media (min-width: 1200px) {
			font-size: 90px;
		}

		.highlight {
			color: $color-primary;
		}
	}

	// Content
	.team-roster__content {
		padding: 20px;

		@media (min-width: 992px) {
			padding: 20px;
		}

		@media (min-width: 1200px) {
			padding: 25px 60px 20px 30px;
		}
	}

	// Excerpt
	.team-roster__player-excerpt {

		@media (min-width: 1200px) {
			margin-bottom: 42px;
		}
	}

	// Meta
	.team-roster__meta {
		border-left: none;
		display: flex;
		justify-content: flex-start;
		flex-grow: 1;
		padding-top: 15px;
		padding-bottom: 15px;
	}
		.team-roster__meta-item {
			text-align: center;
			padding: 10px 15px;

			@media (min-width: 992px) {
				padding: 15px 30px 15px 5px;
			}
		}
			.team-roster__meta-icon {
				width: 40px;
				height: 40px;
				border-radius: 50%;
				border: 4px solid $color-2;
				background-color: $body-bg-color;
				margin: 0 auto 10px auto;
				position: relative;

				.df-icon,
				.fa {
					display: block;
					position: absolute;
					left: 50%;
					top: 50%;
					transform: translate(-50%, -50%);
				}
			}
			.team-roster__meta-icon--more {
				border: none;
				background-color: $color-gray-2;
				transition: background-color .2s ease;

				.fa {
					color: $color-white;
				}

				&:hover {
					background-color: $color-primary;
				}
			}

			.team-roster__meta-label {
				font-size: 10px;
				font-style: italic;
				color: $headings-color;
				white-space: nowrap;
			}

	// Player Image
	.team-roster__player-img {

	}

	.team-roster__player-shape {
		background-color: transparent;

		&::before {
			opacity: 1;
		}

		&::after {
			content: "";
			display: block;
			position: absolute;
			right: 0;
			top: -180px;
			width: 0;
			height: 0;
			border-bottom: 900px solid $card-bg;
			border-left: 320px solid transparent;
			z-index: 3;
		}
	}


	// Background Images
	.team-roster__player-shape--bg1 {

		&::before {
			background-image: url("../images/mmafed/samples/team-player1-bg.jpg");
		}
	}

	.team-roster__player-shape--bg2 {

		&::before {
			background-image: url("../images/mmafed/samples/team-player2-bg.jpg");
		}
	}

	.team-roster__player-shape--bg3 {

		&::before {
			background-image: url("../images/mmafed/samples/team-player3-bg.jpg");
		}
	}

	.team-roster__player-shape--bg4 {

		&::before {
			background-image: url("../images/mmafed/samples/team-player4-bg.jpg");
		}
	}

	.team-roster__player-shape--bg5 {

		&::before {
			background-image: url("../images/mmafed/samples/team-player5-bg.jpg");
		}
	}

	.team-roster__player-shape--bg6 {

		&::before {
			background-image: url("../images/mmafed/samples/team-player-placeholder-bg.jpg");
		}
	}


	.team-roster__player-details-common {
		display: flex;
		flex-wrap: wrap;
		padding-bottom: 20px;
		align-items: center;

		@media (min-width: 992px) {
			padding: 0 0 55px 0;
		}
	}
		.team-roster__player-metrics {
			display: flex;

			@media (max-width: 767px) {
				width: 100%;
			}
		}
			.team-roster__player-metrics-item {
				font-size: 12px;
				line-height: 1.2em;
				padding-right: 16px;

				@media (min-width: 992px) {
					padding-right: 16px;
				}

				@media (min-width: 1200px) {
					padding-right: 50px;
				}
			}
				.team-roster__player-metrics-item-label {
					text-transform: uppercase;
					margin-bottom: .3em;
				}

		.team-roster__player-social {

			@media (max-width: 767px) {
				padding: 15px 0;
			}
		}

	.team-roster__player-stats {
		display: flex;
		margin-bottom: 0;
		align-items: center;
		flex-wrap: wrap;

		@media (min-width: 992px) {
			margin-bottom: 0;
		}

		@media (min-width: 1200px) {
			margin-bottom: 0;
			padding-right: 35px;
		}
	}
		.team-roster__player-stats-circular-bars {
			display: flex;
			flex-wrap: wrap;
		}
			.team-roster__player-stats-circular-bar {
				margin-right: 10px;

				@media (min-width: 768px) {
					margin-right: 15px;
				}

				@media (min-width: 992px) {
					margin-right: 20px;
				}

				@media (min-width: 1200px) {
					margin-right: 38px;
				}


				.circular--size-80 {

					.circular__bar {
						width: 72px;
						height: 72px;

						@media (min-width: 992px) {
							width: 80px;
							height: 80px;
						}

						@media (min-width: 992px) and (max-width: 1199px){
							width: 65px;
							height: 65px;
						}
					}
				}
			}

		.team-roster__player-stats-progress-bars {
			flex: 1;

			.progress-table__title {
				text-transform: uppercase;
			}
			.progress-table__progress-label {
				padding-left: 20px;
			}
		}
}


// Team Roster Navigation
.team-roster-nav {
	display: flex;
	padding: 0 24px;
	margin-bottom: 30px;

	@media (min-width: 992px) {
		padding-top: 10px;
		margin-bottom: 60px;
	}
}
	.team-roster-nav__item {
		margin: 0 auto;
		padding: 17px 0 0 0;

		@media (min-width: 480px) {
			margin-left: 15px;
			margin-right: 15px;
		}

		@media (min-width: 768px) {
			margin-left: 20px;
			margin-right: 20px;
		}

		@media (min-width: 992px) {
			margin-left: 33px;
			margin-right: 33px;
		}

		// Active item
		&:hover,
		&.slick-current {
			cursor: pointer;

			.team-roster-nav__hexagon {
				border-left-color: $color-2;
				border-right-color: $color-2;

				&::before {
					border-top-color: $color-2;
					border-right-color: $color-2;
				}

				&::after {
					border-bottom-color: $color-2;
					border-left-color: $color-2;
				}
			}

			.team-roster-nav__triangle {

				&::before,
				&::after {
					border-bottom-color: $color-2;
				}
			}

			.team-roster-nav__icon {
				border-color: $color-2;
			}

			.team-roster-nav__firstname {
				color: $headings-color;
			}

			.team-roster-nav__nickname {
				color: $color-primary;
			}
		}
	}

		.team-roster-nav__meta {
			text-align: center;
			padding: 15px 0 0 0;
		}
			.team-roster-nav__firstname {
				font-size: 14px;
				line-height: 1.2em;
				text-transform: uppercase;
				margin-bottom: 0;
				font-style: italic;
				color: $body-font-color;
				transition: color .3s ease;
			}
			.team-roster-nav__nickname {
				font-size: 20px;
				line-height: 1.2em;
				margin-bottom: 0;
				transition: color .3s ease;
			}

		.team-roster-nav__hexagon {
			position: relative;
			width: 116px;
			height: 66.97px;
			background-color: $card-bg;
			margin: 33.49px auto;
			border-left: solid 4px $card-border-color;
			border-right: solid 4px $card-border-color;
			transition: border-color .3s ease;

			&::before,
			&::after {
				content: "";
				position: absolute;
				z-index: 1;
				width: 82.02px;
				height: 82.02px;
				transform: scaleY(0.5774) rotate(-45deg);
				background-color: inherit;
				left: 12.9878px;
				transition: border-color .3s ease;
			}

			&::before {
				top: -41.0122px;
				border-top: solid 5.6569px $card-border-color;
				border-right: solid 5.6569px $card-border-color;
			}

			&::after {
				bottom: -41.0122px;
				border-bottom: solid 5.6569px $card-border-color;
				border-left: solid 5.6569px $card-border-color;
			}
		}

			// Player Thumb
			.team-roster-nav__img {
				display: block;
				position: absolute;
				z-index: 2;
				left: 0;
				top: -41px;
			}

			.team-roster-nav__triangle {
				display: block;
				position: absolute;
				left: 0;
				width: 100%;
				bottom: 0;
				z-index: 3;

				&::before,
				&::after {
					content: "";
					display: block;
					position: absolute;
					z-index: 3;
					width: 0;
					height: 0;
					bottom: 4px;
					border-bottom: 41px solid $card-border-color;
					font-size: 0;
					line-height: 0;
					transition: border-color .3s ease;
				}

				&::before {
					left: 0;
					border-right: 68.4px solid transparent;
				}

				&::after {
					right: 0;
					border-left: 68.4px solid transparent;
				}
			}

			.team-roster-nav__triangle-border {
				display: block;
				position: absolute;
				left: 0;
				width: 100%;
				bottom: 0;
				z-index: 4;

				&::before,
				&::after {
					content: "";
					display: block;
					position: absolute;
					z-index: 3;
					width: 0;
					height: 0;
					bottom: 0;
					border-bottom: 41px solid $body-bg-color;
					font-size: 0;
					line-height: 0;
				}

				&::before {
					left: 0;
					border-right: 68.4px solid transparent;
				}

				&::after {
					right: 0;
					border-left: 68.4px solid transparent;
				}
			}

			// Player Type - Icon
			.team-roster-nav__icon {
				display: block;
				position: absolute;
				right: -19px;
				bottom: 95px;
				width: 34px;
				height: 34px;
				border-radius: 50%;
				border: 4px solid $card-border-color;
				background-color: $body-bg-color;
				transition: border-color .3s ease;
				margin: 0 auto;

				.df-icon,
				.fa {
					display: block;
					position: absolute;
					left: 50%;
					top: 50%;
					transform: translate(-50%, -50%);
				}

				.df-icon {
					transform: translate(-50%, -50%) scale(0.75);
				}
			}
