/*
// #Hero Slider - Slider Revolution
// --------------------------------------------------
*/

.rev_slider_wrapper {
	margin-top: 20px;
	padding: 0;
	background-color: $color-dark;

	@media (min-width: 576px) {
		max-width: 524px;
	}

	@media (min-width: 768px) {
		max-width: 704px;
	}

	@media (min-width: 992px) {
		margin-top: 30px;
		max-width: 944px;
	}

	@media (min-width: 1200px) {
		margin-top: 40px;
		max-width: 1170px;
	}

	@media (min-width: 1436px) {
		max-width: 1420px;
	}

	.tp-bannertimer {
		display: none;
	}
}

// Dotted Overlay
.tp-dottedoverlay.fourxfour {
	background-image: url("../../assets/images/gridtile_4x4.gif");
}

// Headings
.alc-hero-slider__h {
	font-family: $font-family-accent;
	color: $headings-color;
	font-weight: 700;
	font-style: italic;
	text-transform: uppercase;
	text-align: left;
}
.alc-hero-slider__h--color-primary {
	color: $color-primary;
}

.alc-hero-slider__h--h1 {
	font-size: 42px;
	line-height: 1em;
	letter-spacing: -0.03em;

	@media (min-width: 576px) {
		font-size: 90px;
	}
}

.alc-hero-slider__h--h2 {
	font-size: 34px;
	line-height: 1em;
	letter-spacing: -0.02em;

	@media (min-width: 576px) {
		font-size: 58px;
	}
}

.alc-hero-slider__h--h5 {
	font-size: 14px;
	line-height: 1.2em;
}

.alc-hero-slider__h--h6 {
	font-size: 12px;
	line-height: 1.2em;
}

// Paragraph
.alc-hero-slider__text {
	font-size: 14px;
	line-height: 1.5em;
}

// Author Avatar
.alc-hero-slider__author-avatar {

}

// Label
.alc-hero-slider__label {

	.posts__cat-label {
		font-size: 10px;
		padding-top: .6em;
	}
}

// Navigation
.metis {

	&.tparrows {
		width: 54px;
		height: 54px;
		padding: 7px;

		@media (max-width: 575px) {
			display: none;
		}

		&::before {
			font-family: 'Font Awesome 5 Free';
			font-weight: 900;
			-webkit-font-smoothing: antialiased;
			-moz-osx-font-smoothing: grayscale;
			font-size: 20px;

			@if $template == 'mmafed' {
				color: $color-gray-3;
			}
		}

		&.tp-leftarrow {

			&::before {
				content: "\f104";
			}
		}

		&.tp-rightarrow {

			&::before {
				content: "\f105";
			}
		}

		&:hover {
			background-color: $color-primary;


			&::before {
				color: $color-white;
			}
		}
	}
}
