/* Widget: Results */
.widget-results {

	.card__content {
		padding: 0;
	}
}
	.widget-results__list {
		list-style: none;
		padding: 0;
		margin: 0;
	}
		.widget-results__item {
			border-top: 1px solid $card-border-color;

			&:first-child {
				border-top: none;
			}
		}
			.widget-results__header {
				display: flex;
				font-family: $font-family-accent;
				text-transform: $widget-results-title-text-transform;
				font-size: $widget-results-title-font-size;
				line-height: 1.2em;
				text-align: center;
				font-weight: 400;
				font-style: normal;
				color: $body-font-color;
				border-bottom: 1px solid $card-border-color;
				padding: $widget-results-title-vertical-padding $card-horizontal-padding;
				margin-bottom: 0;
			}
			.widget-results__title {

			}

			.widget-results__content {
				display: flex;
				align-items: center;
				padding: 12px $card-horizontal-padding/2;

				@media (min-width: 1200px) {
					padding: 16px $card-horizontal-padding;
				}
			}
				.widget-results__team {
					flex-grow: 1;

					@media (min-width: 992px) and (max-width: 1199px) {
						flex-basis: 25%;
					}
				}
					.widget-results__team-logo {
						display: inline-block;
						vertical-align: middle;
						margin-right: 5px;
						width: 20px;
						text-align: center;

						@media (min-width: 992px) {
							margin-right: 8px;
							width: 28px;
						}

						.widget-results__team--second & {
							float: right;
							margin-right: 0;
							margin-left: 5px;

							@media (min-width: 992px) {
								margin-right: 0;
								margin-left: 8px;
							}
						}
					}
					.widget-results__team-details {
						display: inline-block;
						vertical-align: middle;
					}
						.widget-results__team-name {
							text-transform: none;
							font-size: 11px;
							font-weight: 400;
							line-height: 1.2em;
							font-style: normal;
							margin: 0;
							overflow: hidden;
							text-overflow: ellipsis;
							white-space: nowrap;

							@media (min-width: 1200px) {
								font-size: 12px;
							}
						}
						.widget-results__team-info {
							font-family: $font-family-accent;
							font-size: 8px;
							@include fix_small_chars();
							display: block;
							line-height: 1.2em;

							@media (min-width: 992px) {
								font-size: 9px;
							}
						}

					.widget-results__team--second {
						text-align: right;
					}

				.widget-results__result {
					flex-grow: 1;
					text-align: center;
				}
					.widget-results__score {
						font-family: $font-family-accent;
						color: $headings-color;
						font-size: 10px;
						line-height: 1.2em;
						margin-bottom: 0;

						@media (min-width: 992px) {
							font-size: 12px;
						}

					}
						.widget-results__score-winner {

							&:before {
								content:"";
								display: inline-block;
								border-top: 4px solid transparent;
								border-bottom: 4px solid transparent;
								border-left: 4px solid $color-primary;

								@if $template == 'football' {
									border-left: 4px solid $color-4;
								} @else {
									border-left: 4px solid $color-primary;
								}

								width: 0;
								height: 0;
								margin-right: .5em;
							}

							.widget-results__score-loser ~ &::before {
								display: none;
							}

							.widget-results__score-loser ~ &::after {
								content:"";
								display: inline-block;
								border-top: 4px solid transparent;
								border-bottom: 4px solid transparent;
								border-right: 4px solid $color-primary;

								@if $template == 'football' {
									border-right: 4px solid $color-4;
								} @else {
									border-right: 4px solid $color-primary;
								}

								width: 0;
								height: 0;
								margin-left: .5em;
							}
						}
						.widget-results__score-loser {

							@if $template == 'mmafed' {
								color: $body-font-color;
							}
						}

						.widget-results__status {
							display: block;
							font-size: 8px;
							@include fix_small_chars();
							font-family: $font-family-accent;
							line-height: 1.2em;
							color: $body-font-color;

							@media (min-width: 992px) {
								font-size: 9px;
							}
						}


	// Event - definition list
	.widget-results__dl {
		margin: -1px 0 0 0;
		padding: 0;
	}
		.widget-results__dt {
			font-size: $font-style-h6 - 1px;
			font-style: $font-style-h5;
			color: $headings-color;
			margin: 0;
			border-top: 1px solid $card-border-color;
			border-bottom: 1px solid $card-border-color;
			padding: 5px $card-horizontal-padding / 2;
			position: relative;

			&::before {
				content: "";
				display: block;
				position: absolute;
				left: 0;
				top: 0;
				bottom: 0;
				width: 6px;
				background-color: $color-primary;
			}

			@media (min-width: 1200px) {
				padding: 10px $card-horizontal-padding;
			}
		}
		.widget-results__dt--category-1::before {
			background-color: $post-category-1;
		}
		.widget-results__dt--category-2::before {
			background-color: $post-category-2;
		}
		.widget-results__dt--category-3::before {
			background-color: $post-category-3;
		}
		.widget-results__dt--category-4::before {
			background-color: $post-category-4;
		}

		.widget-results__dd {
			margin: 0;
			padding: 0;
		}
