/* Widget: Team Stats */
.widget-team-stats {


}
	.team-stats-box {
		list-style: none;
		padding: 0;
		margin: 0;
		display: flex;
		flex-wrap: wrap;
		border-style: solid;
		border-color: $card-border-color;
		border-width: 1px 0 0 1px;
		margin: -1px;
	}
		.team-stats__item {
			flex: 0 1 50%;
			max-width: 50%;
			border-style: solid;
			border-color: $card-border-color;
			border-width: 0 1px 1px 0;
			text-align: center;
			padding: 24px 0 60px 0;
			background-image: linear-gradient(to right, $widget-decor-color-1 4px, transparent 4px), linear-gradient(to right, $widget-decor-color-1 4px, transparent 4px), linear-gradient(to right, $widget-decor-color-1 4px, transparent 4px), linear-gradient(to right, $widget-decor-color-1 4px, transparent 4px), linear-gradient(to right, $widget-decor-color-1 4px, transparent 4px), linear-gradient(to right, $widget-decor-color-1 4px, transparent 4px), linear-gradient(to right, $widget-decor-color-2 4px, transparent 4px), linear-gradient(to right, $widget-decor-color-3 4px, transparent 4px),;
			background-repeat: repeat-x;
			background-size: 36px 24px, 36px 30px, 36px 34px, 36px 40px, 36px 37px, 36px 29px, 72px 30px, 108px 37px;
			background-position: 0 100%, 6px 100%, 12px 100%, 18px 100%, 24px 100%, 30px 100%, 6px 100%, 24px 100%;

			@media (min-width: 992px) {
				padding: 34px 0 80px 0;
			}
		}
		.team-stats__item--clean {
			background: none;
			padding: 24px 0 20px 0;

			@media (min-width: 992px) {
				padding: 26px 0 28px 0;
			}
		}
			.team-stats__icon {
				height: 37px;
			}
			.team-stats__icon--circle {
				position: relative;
				width: 90px;
				height: 90px;
				border-radius: 50%;
				margin: 0 auto 16px auto;
				background-color: $color-primary;
				overflow: hidden;

				.icon-soccer-ball {
					font-size: 30px;
				}
			}

			.team-stats__icon--circle-outline {
				position: relative;
				border-radius: 50%;
				margin: 0 auto;
				border: 4px solid $color-primary;
			}

			.team-stats__icon--size-sm {
				width: 40px;
				height: 40px;
				margin-bottom: 16px;
			}

			.team-stats__icon--centered {

				.df-icon,
				.fa {
					position: relative;
					top: 50%;
					transform: translateY(-50%);
					text-align: center;
				}

				.fa {
					display: block;
					font-size: 18px;
					color: $headings-color;
				}
			}


			.team-stats__indicator {
				display: block;
				position: absolute;
				right: -6px;
				top: -6px;
				border: 2px solid $color-primary;
				width: 16px;
				height: 16px;
				border-radius: 50%;
				font-size: 12px;
				line-height: 12px;
				font-family: $font-family-accent;
				font-weight: bold;
				color: $color-white;
				background-color: $card-bg;
			}


			.team-stats__icon--shots-ot {
				background-color: #a4ec32;

				.team-stats__icon-primary {
					transform: translate(-50%, -50%) translate(-5px, -2px);
					z-index: 1;
				}
				.team-stats__icon-secondary {
					display: block;
					position: absolute;
					left: 0;
					top: 0;
					transform: translate(32px, 37px);
				}
			}
			.team-stats__icon--shots {
				background-color: #f5d109;

				.team-stats__icon-primary {
					transform: translate(-50%, -50%) translate(-5px, 2px);

					.icon-svg {
						font-size: 27px;
					}
				}
				.team-stats__icon-secondary {
					display: block;
					position: absolute;
					left: 0;
					top: 0;
					transform: translate(58px, 16px);
				}
			}
			.team-stats__icon--assists {
				background-color: #3adfc2;

				.team-stats__icon-secondary {
					display: block;
					position: absolute;
					left: 0;
					top: 0;
					width: 16px;
					height: 16px;
					border-radius: 50%;
					background-color: $color-2;
					font-family: $font-family-accent;
					font-size: 10px;
					line-height: 16px;
					font-weight: 700;
					text-align: center;
					color: #fff;
					text-transform: uppercase;
					transform: translate(52px, 54px);
				}
			}
				.team-stats__icon-primary {
					display: block;
					position: relative;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
				}


			.team-stats__value {
				@include font-accent(24px);
				color: $headings-color;
				line-height: 1.2em;
				margin-bottom: .2em;

				@if $template == football {
					margin-bottom: .1em;
				}

				@media (min-width: 1200px) {
					font-size: 36px;
				}
			}
			.team-stats__label {
				@include font-accent(8px);
				font-weight: 400;
				line-height: 1.2em;

				@if $template == 'mmafed' {
					font-size: 9px;
					text-transform: none;
					color: $headings-color;
					font-weight: 700;
					margin-bottom: .2em;
				}

				@media (min-width: 768px) {

					@if $template == 'mmafed' {
						font-size: 12px;
					} @else {
						font-size: 10px;
					}
				}
			}

			.team-stats__label + .team-stats__circular {
				margin-top: 20px;
			}

			.team-stats__sublabel {
				font-size: 9px;
				line-height: 1.2em;
			}

			.team-stats__circular {

				.circular__bar {
					width: 20px;
					height: 20px;
					margin-bottom: 5px;
				}

				.circular__bar + .circular__label {
					margin-top: 0;
				}
				.circular__percents {
					font-size: 10px;
					line-height: 1em;
				}
			}
				.team-stats__circular--minus {
					color: $color-danger;

					.circular__percents {
						color: $color-danger;
					}
				}

				.team-stats__circular--plus {
					color: $color-success;

					.circular__percents {
						color: $color-success;
					}
				}


	// Cell Background
	.team-stats-box--cell-bg {
		background-image: url("../images/football/graph-bg.png"), linear-gradient(to right, rgba($card-border-color, 0.4) 1px, transparent 1px), linear-gradient(to bottom, rgba($card-border-color, 0.4) 1px, transparent 1px);
		background-repeat: no-repeat, repeat-x, repeat-y;
		background-size: auto auto, 15px 100%, 100% 15px;
		background-position: 50% 100%, -7px 0, 0 5px;
	}
