
/*=============================================
=            mmafed Posts            =
=============================================*/

// Category Label
.posts__cat {
	padding-left: 4px;
	margin-bottom: 4px;
}

// Category Labels
.posts__cat-label {
	background-color: transparent !important;
	position: relative;
	z-index: 1;

	&::before {
		content: "";
		display: block;
		position: absolute;
		z-index: -1;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		transform: skewX(-15deg);
		background-color: #fff;
	}
}

.posts__cat-label--category-1 {
	&::before {
		background-color: $post-category-1;
	}
}

.posts__cat-label--category-2 {
	&::before {
		background-color: $post-category-2;
	}
}

.posts__cat-label--category-3 {
	&::before {
		background-color: $post-category-3;
	}
}

.posts__cat-label--category-4 {
	&::before {
		background-color: $post-category-4;
	}
}

.posts__cat-label--category-5 {
	&::before {
		background-color: $post-category-5;
	}
}

.posts__cat-label--category-6 {
	&::before {
		background-color: $post-category-6;
	}
}


/* Simple Posts List */
.posts--simple-list {

	&:not(.posts--simple-list--search) {

		.posts__inner {
			padding-left: 6px;
		}
	}

	.posts__title {

		& + .posts__date {
			margin-top: 0;
		}
	}

	.widget--footer & {

		.posts__title {

			& + .posts__date {
				margin-top: 0;
			}
		}

		.posts__date {
			font-style: italic;
		}
	}
}

/*=====  End of mmafed Posts  ======*/
