/*=============================================
=            mmafed Footer            =
=============================================*/

/* Footer Widgets */
.footer-widgets {
	position: relative;

	@media (max-width: 767px) {
		background-image: none;
	}

	&::before {
		content: "";
		display: block;
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		background: $footer-widgets-bg;
		background-position: 50% 0;
		background-repeat: no-repeat;
		background-size: cover;
	}

	// MS Edge fallback
	@supports (position:-ms-page) {
		&::after {
			content: "";
			display: block;
			position: absolute;
			left: 0;
			top: 0;
			right: 0;
			bottom: 0;
			background-color: $card-bg;
			opacity: .9;
		}
	}

	// IE11 fallback
	@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
		&::after {
			content: "";
			display: block;
			position: absolute;
			left: 0;
			top: 0;
			right: 0;
			bottom: 0;
			background-color: $card-bg;
			opacity: .9;
		}
	}

	.footer-widgets__inner {
		position: relative;
		z-index: 4;
	}
}

/* Sponsors */
.sponsors-wrapper {
	background-color: $color-dark;

	.sponsors {
		border-top: 0;
		display: block;

		@media (min-width: 768px) {
			margin-top: 0;
			padding: 40px 0;
		}

		@media (min-width: 992px) {
			padding: 60px 0;
		}

		@media (min-width: 992px) {
			margin-top: 0;
		}
	}

	.sponsors-logos {
		align-items: center;
		justify-content: space-around;
	}

		.sponsors__item {

			@media (min-width: 1200px) {
				margin: 0 20px;
			}

			> a {
				opacity: 0.1;

				&:hover {
					opacity: 1;
				}
			}
		}
}

/* Footer Social */
.footer-social {
	background: $footer-secondary-bg;
}
	.footer-social__list {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		align-items: center;
		padding: 25px 0 20px 0;
		margin: 0;
	}
		.footer-social__item {
			margin: 0;

			@media (min-width: 768px) {
				margin: 0 10px;
			}

			@media (min-width: 992px) {
				margin: 0;
			}

			@media (min-width: 1200px) {
				margin: 0 15px;
			}
		}
			.footer-social__link {
				display: flex;
				align-items: center;
				padding: 5px 10px 10px 10px;
			}
				.footer-social__icon {
					font-size: 22px;
				}
				.footer-social__txt {
					text-transform: uppercase;
					font-weight: 700;
					font-family: $font-family-accent;
					display: none;

					@media (min-width: 992px) {
						display: block;
						padding: 0 0 0 15px;
					}
				}
					.footer-social__name {
						display: block;
						font-size: 12px;
						line-height: 1.2em;
						padding-bottom: .25em;
						color: #fff;
					}
					.footer-social__user {
						display: block;
						font-size: 10px;
						line-height: 1.2em;
						font-style: italic;
						color: #a59cae;
					}
